import { AppShell, Box, Button, Container, Flex, Image, Text, useMatches } from '@mantine/core'
import ReactPlayer from 'react-player'
import LogoWhite from 'assets/images/ttlogowithnamewhite.svg'
import { useRef } from 'react'

export function Landing() {
  const size = useMatches({
    base: 'md',
    md: 'md',
    lg: 'lg',
  })

  const margins = useMatches({
    base: 20,
    md: 30,
    lg: 50,
  })

  const marginsBetweenHeader = useMatches({
    base: 30,
    md: 40,
    lg: 40,
  })

  const marginsBetweenVideo = useMatches({
    base: 20,
    xs: 40,
    sm: 50,
    md: 60,
  })

  const headerSize = useMatches({
    base: '40px',
    md: '45px',
    lg: '50px',
  })

  const videoRef = useRef<ReactPlayer>(null!)

  const beginningHandler = () => {
    const videoTag = videoRef.current.getInternalPlayer() as HTMLVideoElement
    videoTag.style.objectFit = 'cover'
  }

  return (
    <Container h="100%" p="sm" bg="dark.9">
      <Box
        h={90}
        mb={60}
        display="flex"
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Image fit="contain" h={70} w={120} src={LogoWhite} alt="TouchTakeoff Logo" />
        <Button
          variant="white"
          color="dark"
          size={size}
          radius="xl"
          onClick={() => window.open(import.meta.env.VITE_FRONTEND_URL, '_self')}
        >
          Get Started
        </Button>
      </Box>
      <Box
        display="flex"
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
        mb={marginsBetweenVideo}
      >
        <Text display="flex" mb={10} size={headerSize} fw={600} c="white">
          Takeoff should be
        </Text>
        <Text size={headerSize} fw={700} ml={10} mb={marginsBetweenHeader} td="underline" c="white">
          simple
        </Text>
        <Text lh="120%" lts={1.2} maw={900} mb={30} size="1.2rem" ta="justify" fw={500} c="white">
          TouchTakeoff is a web-based takeoff software designed with simplicity and accessibility in mind. Our intuitive
          interface ensures that users of all skill levels can easily navigate and utilize the powerful features of
          TouchTakeoff, making project takeoff more efficient and effective.
        </Text>

        <Button
          variant="white"
          color="dark"
          size={size}
          radius="xl"
          onClick={() => window.open(import.meta.env.VITE_FRONTEND_URL, '_self')}
        >
          Sign Up for Free
        </Button>
      </Box>
      <Box
        mb={margins}
        display="flex"
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ReactPlayer
          ref={videoRef}
          url="https://cdn.touchtakeoff.online/promo-video.mp4"
          muted
          playing
          loop
          style={{
            borderRadius: 20,
            overflow: 'hidden',
          }}
          controls={true}
          onStart={beginningHandler}
        />
      </Box>
    </Container>
  )
}
