/* eslint-disable @typescript-eslint/naming-convention */
import type { MantineThemeOverride } from '@mantine/core'

export const theme: MantineThemeOverride = {
  primaryColor: 'custom-primary-color',
  fontFamily: 'Lato',
  colors: {
    'custom-primary-color': [
      '#F0F5FF',
      '#D6E4FF',
      '#ADC6FF',
      '#85A5FF',
      '#597EF7',
      '#3366CC',
      '#254EA8',
      '#1D3D87',
      '#132C66',
      '#0C1A46',
    ],
    'custom-secondary-color': [
      '#FFFAF0',
      '#FFEED6',
      '#FFDFAD',
      '#FFC785',
      '#FFA759',
      '#CC8833',
      '#A85425',
      '#87411D',
      '#663313',
      '#461F0C',
    ],
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '4rem',
  },
  breakpoints: {
    xs: '30em',
    sm: '48em',
    md: '64em',
    lg: '74em',
    xl: '90em',
  },
}
