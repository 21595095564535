import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import detector from 'i18next-browser-languagedetector'
import translationsFr from './locales/fr/trans.json'
import translationsEn from './locales/en/trans.json'

export enum Language {
  EN = 'en',
  FR = 'fr',
}

export enum NS {
  translation = 'translation',
  session = 'session',
}

void i18next
  .use(detector)
  .use(initReactI18next)
  .init({
    fallbackLng: Language.FR,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      [Language.FR]: {
        [NS.translation]: translationsFr,
      },
      [Language.EN]: {
        [NS.translation]: translationsEn,
      },
    },
  })

export default i18next
